<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-6">
                  <v-btn color="primary" @click="setDatas"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <div v-for="string in stringboxNameList" :key="string.value">
                <div style="border: 1px solid black">
                  <div class="text-center col-12 ml-2 mt-2">
                    <b> {{ string.text }}</b>
                  </div>
                  <div :id="'d3jsdccurrent' + string.value" class="svgclass" />
                </div>
              </div>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as Heatmap from "@/api/CombinerBox/Heatmap";
import * as d3 from "d3";
import moment from "moment";

export default {
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format("YYYY-MM-DD"),
      finishDate: moment().format("YYYY-MM-DD"),
      menu: false,
      modal: false,
      menu2: false,
      measures: [],
      measureSelect: "DCCurrent",
      componentKey: 1,
      title: "",
      santralModel: "",
      inverterList: [],
      height: "",
      HeatmapData: [],
      stringboxList: ["COMBINER01", "COMBINER02"],
      stringboxNameList: [],
      stepTime: 5,
    };
  },
  watch: {
     
  },
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
       
      return santralDataList;
    },
  },
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;
    function myTimer1() {
      const { stepTime } = self.$store.getters;
      const { santral } = self.$store.getters.selectedSantralModel;
      const StatsMeasure = self.$store.getters.santralInfo;
      if (santral !== undefined && Object.keys(StatsMeasure).length > 0) {
        self.santralModel = santral;
        self.santralInfo = StatsMeasure;
        self.stepTime = stepTime;
        self.setData();
        clearInterval(myVar1);
      }
    }
  },
  methods: {
    setDatas() {
      const node = document.getElementById("stringboxListD3js");
      this.stringboxNameList = [];
      if (node !== null) {
        node.remove();
      }
      setTimeout(() => {
        this.setData();
      }, 500);
    },
    setData() {
      let self = this;
      const { stringboxInfo } = self.$store.getters;
      this.stringboxNameList = [];
      const dc_current = {};
      self.stringboxList = [];
      const stringboxNameList = [];

      const promise = new Promise((res, reject) => {
        Object.keys(stringboxInfo[self.santralModel]).forEach((k) => {
          self.stringboxList.push(k);

          stringboxNameList.push({
            value: k,
            text: stringboxInfo[self.santralModel][k].STRINGBOXNAME,
          });

          const { stringQuantity } = stringboxInfo[self.santralModel][k];

          dc_current[k] = [];

          if (stringQuantity > 0) {
            for (let i = 1; i <= stringQuantity; i++) {
              let text;
              i < 10 ? (text = `DCCurrent0${i}`) : (text = `DCCurrent${i}`);

              dc_current[k].push(text);
            }
          } else {
            let text = "DCCurrent";

            dc_current[k].push(text);
          }

          res(dc_current);
        });
      });

      this.stringboxNameList = stringboxNameList;

      const step_Time = this.stepTime * 2;
      const MinuteGrup = [];
      for (let mm = this.stepTime; mm < 60; mm += step_Time) {
        MinuteGrup.push(mm);
      }

      promise.then((dc_current) => {
        const formdata = {
          condiniton: {
            santral: this.santralModel,
            startDate: this.startDate,
            finishDate: this.finishDate,
            stringbox: this.stringboxList,
            maxcount: 3000,
            MinuteGrup,
          },
        };

        const temp = Heatmap.default.heatmapDailyMeans_string_diff(
          formdata,
          this.measureSelect,
          stringboxInfo[this.santralModel],
          stringboxNameList,
          dc_current
        );

        temp.then((data) => {
          Object.keys(data).forEach((item, index) => {
            this.heatmaps(data[item], item, index);
          });
          //
        });
      });
    },
    heatmaps(data, string, index) {

      let stringname = string;
      Object.keys(this.stringboxNameList).forEach((item) => {
        if (this.stringboxNameList[item].value === string) {
          stringname = this.stringboxNameList[item].text;
        }
      });
       
      const margin = {
        top: 80,
        right: 10,
        bottom: 60,
        left: 100,
      };
      const width = 1200 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      const svg = d3
        .select(`#d3jsdccurrent${string}`)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const myGroups = d3.map(data, (d) => d.time).keys();
      const myVars = d3.map(data, (d) => d.dc_current).keys();

      // Build X scales and axis:
      const x = d3.scaleBand().range([0, width]).domain(myGroups).padding(0.05);

      svg
        .append("g")
        .style("font-size", 12)
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", "-.5em")
        .attr("transform", "rotate(-85)");

      // Build Y scales and axis:
      const y = d3.scaleBand().range([height, 0]).domain(myVars).padding(0.05);
      svg
        .append("g")
        .style("font-size", 12)
        .call(d3.axisLeft(y).tickSize(0))
        .select(".domain");

      const toolTip = d3
        .select(`#d3jsdccurrent${string}`)
        .append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("text-align", "center")
        .style("padding", "30px")
        .style("background", "#24b1d1")
        .style("border", "0px")
        .style("border-radius", "8px")
        .style("opacity", 0);

      const mouseover = function (d) {
        toolTip.transition().duration(1).style("opacity", 0.9);
        toolTip
          .html(d.html)
          .style("left", `${d3.event.pageX}px`)
          .style("top", `${d3.event.pageY}px`);
      };

      const startdate = this.startDate;
      let unit = "";
      this.measures.forEach((measure) => {
        if (this.measureSelect === measure.value) {
          unit = measure.unit;
        }
      });

      let self = this;

      const mousemove = function (d) {
        let mean = 0;

        if (d.mean > 0) {
          mean = d.mean.toFixed(2);
        }

        const { navbarShow } = self.$store.getters;

        let val = 0;
        if (navbarShow === true) {
          val = 400;
        }

        let text;
        if (d.dc_current == "DCCurrent") {
          text = `Santral:${self.santralModel}<br>String :${stringname}<br>${startdate} ${d.time} <br> ${d.dc_current} 
            : ${d.value} A`;
        } else {
          text = `Santral:${self.santralModel}<br>String :${stringname}<br>${startdate} ${d.time} <br> ${d.dc_current} 
            : ${d.value} A <br>Ortalama : ${mean} A`;
        }

        toolTip
          .html(text)
          .style("left", `${d3.event.pageX - val}px`)
          .style("top", `${d3.event.pageY}px`);
      };
      const mouseleave = function (d) {
        toolTip.style("opacity", 0);
        d3.select(this).style("stroke", "none").style("opacity", 0.9);
      };

      // add the squares
      svg
        .selectAll()
        .data(data, (d) => `${d.time}:${d.dc_current}`)
        .enter()
        .append("rect")
        .attr("font-size", 11)
        .attr("x", (d) => x(d.time))
        .attr("y", (d) => y(d.dc_current))
        .attr("rx", 4)
        .attr("ry", 4)
        .attr("width", x.bandwidth())
        .attr("height", y.bandwidth())
        .style("fill", (d) => d.color)
        .style("stroke-width", 4)
        .style("stroke", "none")
        .style("opacity", 0.8)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
